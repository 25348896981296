import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals, fadeInOut } from 'src/app/globals';
import { faUser, faPhoneAlt, faPaperPlane, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  animations: [fadeInOut]
})
export class ContactComponent implements OnInit {

  @ViewChild('form', {static: false}) form;

  public name: string;
  public surname: string;
  public phone: string;
  public mail: string;
  public message: string;

  faUser = faUser;
  faPhoneAlt = faPhoneAlt;
  faPaperPlane = faPaperPlane;
  faCommentDots = faCommentDots;

  // public myRecaptcha: boolean;
  myRecaptcha = new FormControl(false);

  constructor(public globals: Globals) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
  }

  onScriptLoad() {
      console.log('Google reCAPTCHA loaded and is ready for use!');
      // console.log(this.myRecaptcha);
  }

  onScriptError() {
      console.log('Something went long when loading the Google reCAPTCHA');
  }

  onSubmit(form) {
    console.log(this.form);
    /*if (this.myRecaptcha.status === 'VALID') {

    }*/
  }

}
