import { Component, OnInit } from '@angular/core';
import { fadeInOut, Globals } from 'src/app/globals';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.css'],
  animations: [fadeInOut]
})
export class CookiesPolicyComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
