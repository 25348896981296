import {animate, style, transition, trigger, state} from '@angular/animations';
import {Injectable} from '@angular/core';
import * as $ from 'jquery';
import { Product } from './model/product';
import * as tripledes from 'crypto-js/tripledes';
import * as CryptoJS from 'crypto-js';
import * as forge from 'node-forge';
import { ServerService } from './services/server.service';
import { plainToClass } from 'class-transformer';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { load } from 'recaptcha-v3';


export const fadeInOut = trigger(
  'fadeInOut', [
    transition(':enter', [
      style({opacity: 0}),
      animate('600ms 550ms', style({opacity: 1}))
    ]),
    transition(':leave', [
      style({opacity: 0}),
      animate('600ms', style({opacity: 0}))
    ])
  ]
);

export const growUpDown = trigger(
  'growUpDown', [
    transition(':enter', [style({height: 0, overflow: 'hidden'}), animate('1s ease', style({height: '*', opacity: 1}))]),
    transition(':leave', [style({height: '*', overflow: 'hidden'}), animate('1s ease', style({height: 0, opacity: 0}))])
  ]
);

export const rotateArrow = trigger(
  'rotateArrow', [
    state('default', style({ transform: 'rotate(0)' })),
    state('rotated', style({ transform: 'rotate(90deg)' })),
    transition('rotated => default', animate('400ms ease-out')),
    transition('default => rotated', animate('400ms ease-in'))
  ]
);

@Injectable()
export class Globals {

  public stateLanguage = 'default';
  public stateBrands = 'default';
  public certificateURL = '';
  public urlChrome = '';
  public urlExplorer = '';
  public urlEdge = '';
  public urlSafari = '';
  public urlFirefox = '';
  public urlOpera = '';
  public shoppingCart: Array<Product> = [];
  public vat = 21;
  private product: Product;
  public recaptcha = false;

  constructor(private serverService: ServerService, private translate: TranslateService) {
    load('6LfijLUUAAAAAB7GA7mN_oCiWqXZFqGtnbu8nhvo', {
      useRecaptchaNet: true
    }).then((recaptcha) => {
      recaptcha.execute('ecommerce').then((token) => {
        console.log(token);
        this.recaptcha = true;
        this.serverService.verifyReCaptcha('6LfijLUUAAAAAOYYrbl6k8sm1Ocy8nTPm7ql3UJF', token).subscribe(response => {
          console.log(response.body);
          var json = JSON.parse('{ "success": true, "challenge_ts": "2020-02-20T12:04:59Z", "hostname": "localhost", "score": 0.9, "action": "ecommerce" }');
          this.recaptcha = json['success'];
        }, (error) => {
          console.error(error);
        });
      });
    });
   }

  /**
   * Select default menu
   */
  selectDefaultMenu() {
    $('#home, #brands, #contact, #about_us, #cart').each(
      function() {
        const actualMenuHome = $('#home').hasClass('current-menu-item');
        const actualMenuBrands = $('#brands').hasClass('current-menu-item');
        const actualMenuContact = $('#contact').hasClass('current-menu-item');
        const actualMenuAboutUs = $('#about_us').hasClass('current-menu-item');
        const actualCart = $('#cart').hasClass('current-menu-item');
        $(this).hover(
          function() {
            $('*').removeClass('current-menu-item');
            $(this).addClass('current-menu-item');
            document.querySelectorAll('#cart')[0].classList.add('cart-selector');
          }, function() {
            $('*').removeClass('current-menu-item');
            if (actualMenuHome) {
              $('#home').addClass('current-menu-item');
            } else if (actualMenuBrands) {
              $('#brands').addClass('current-menu-item');
            } else if (actualMenuContact) {
              $('#contact').addClass('current-menu-item');
            } else if (actualMenuAboutUs) {
              $('#about_us').addClass('current-menu-item');
            } else if (actualCart) {
              $('#cart').addClass('current-menu-item');
              document.querySelectorAll('#cart')[0].classList.add('cart-selector');
            }
          }
        )
      }
    );
  }

  addToCart(id: number) {
    this.serverService.getProductByID(id).subscribe(response => {
      this.product = plainToClass(Product, response.body);
      this.shoppingCart.push(this.product);
      let message = '';
      this.translate.get('PRODUCT_ADDED_TO_THE_CART').subscribe(value => { message = value; });
      Swal.fire({
        icon: 'success',
        title: message,
        showConfirmButton: true
      });
    }, (error) => {
      console.log(error);
    });
    // Replace for get product from DB
    /*if (name == "VS9056 Scope 1400")
    {
      this.product = new Product();
      this.product.NAME = name;
      this.product.PRICE = 6391;
      this.product.QUANTITY = 1;
      //this.product.VAT = 21;
      this.shoppingCart.push(this.product);
    }*/
  }

  rotate(type: string) {
    switch (type) {
      case 'language':
        this.stateLanguage = (this.stateLanguage === 'default' ? 'rotated' : 'default');
        break;
      case 'brands':
        this.stateBrands = (this.stateBrands === 'default' ? 'rotated' : 'default');
        break;
      default:
        break;
    }
  }

}
