import {JsonObject, JsonProperty} from 'json2typescript';

export class Address {

    @JsonProperty('NAME', String)
    NAME: string;
    @JsonProperty('ADDRESS', String)
    ADDRESS: string;
    @JsonProperty('CP', String)
    POSTAL_CODE: string;
    @JsonProperty('COUNTRY', String)
    COUNTRY: string;

    constructor() {
      this.NAME = '';
      this.ADDRESS = '';
      this.POSTAL_CODE = '';
      this.COUNTRY = '';
    }
  }
