import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule, APP_ROUTING } from './app-routing.module';
import { AppComponent } from './components/main/app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InlineSVGModule } from 'ng-inline-svg';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Globals } from './globals';
import { MoviltestComponent } from './components/moviltest/moviltest.component';
import { AvlditestComponent } from './components/avlditest/avlditest.component';
import { CarbonzappComponent } from './components/carbonzapp/carbonzapp.component';
import { SonicComponent } from './components/sonic/sonic.component';
import { CarboncleanComponent } from './components/carbonclean/carbonclean.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { ContactComponent } from './components/contact/contact.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


export function HttpLoaderFactory(http: HttpClient) {
  registerLocaleData(es);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    MoviltestComponent,
    AvlditestComponent,
    CarbonzappComponent,
    SonicComponent,
    CarboncleanComponent,
    AboutUsComponent,
    CookiesPolicyComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    LoginComponent,
    FooterComponent,
    ShoppingCartComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    InlineSVGModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    RecaptchaModule.forRoot({
      siteKey: '6Le3kLUUAAAAAGAYjvhmELFeoKzb6d8BD-lj487D',
    }),
    FontAwesomeModule,
    FormsModule,
    APP_ROUTING,
    NgbModule
  ],
  providers: [AppRoutingModule, Globals, [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}], {provide: LOCALE_ID, useValue: 'es'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
