import { Component, OnInit, } from '@angular/core';
import { faDesktop, faHeart, faPaintBrush, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { fadeInOut, Globals } from 'src/app/globals';
declare var $ : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [fadeInOut]
})
export class HomeComponent implements OnInit {

  /* FontAwesome Icons */
  faDesktop = faDesktop;
  faHeart = faHeart;
  faPaintBrush = faPaintBrush;
  faSlidersH = faSlidersH;

  capatestShow = true;
  carbonZappShow = true;
  carbonCleanShow = true;
  sonicShow = true;
  moviltestShow = true;
  avlShow = true;

  constructor(private globals: Globals) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
    $('#capatestCarousel').carousel('cycle');
    $('#capatestCarousel').hover(() => {
        $('#capatestCarousel').carousel('cycle');
    });
  }

}
