import { Component, OnInit } from '@angular/core';
import { fadeInOut, Globals } from 'src/app/globals';
declare var $ : any;

@Component({
  selector: 'app-sonic',
  templateUrl: './sonic.component.html',
  styleUrls: ['./sonic.component.css'],
  animations: [fadeInOut]
})
export class SonicComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
    $('#sonicCarousel').carousel('cycle');
    $('#sonicCarousel').hover(() => {
      $('#sonicCarousel').carousel('cycle');
    });
  }

  openCatalog(catalogType: string)
  {
    switch (catalogType)
    {
      case 'cupboard':
        window.open('https://issuu.com/intermediamakers/docs/chapter_01_catalogue_uk_2018?e=1105532/57056307', '_blank');
        break;
      case 'toolkit':
        window.open('https://issuu.com/intermediamakers/docs/chapter_06_catalogue_uk_2018?e=1105532/57056371', '_blank');
        break;
      case 'consumables':
        window.open('https://issuu.com/intermediamakers/docs/chapter_01_catalogue_uk_2018?e=1105532/57056307', '_blank');
        break;
      case 'carriages':
        window.open('https://issuu.com/intermediamakers/docs/chapter_02_catalogue_uk_2018?e=1105532/57056320', '_blank');
        break;
      default:
        break;
    }
  }

}
