import { Component, OnInit, Input } from '@angular/core';
import { Globals, fadeInOut } from 'src/app/globals';
import { Product } from 'src/app/model/product';
import { Payment } from 'src/app/model/payment';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerService } from '../../services/server.service';
import { PaymentReq } from 'src/app/model/paymentReq';
import { plainToClass } from 'class-transformer';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'src/app/model/address';
import { load } from 'recaptcha-v3';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css'],
  animations: [fadeInOut]
})
export class ShoppingCartComponent implements OnInit {

  public product: Product;
  public paymentObj: Payment;
  public paymentReqObj: PaymentReq;
  public invoicingAddress: Address;
  public forwardingAddress: Address;
  public subTotal = 0.00;
  public totalPrice = 0.00;
  public paymentResult = '';
  public message = '';

  constructor(public globals: Globals, private routeAR: ActivatedRoute, private serverService: ServerService, private translate: TranslateService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
    this.routeAR.queryParams.subscribe(params => {
      this.paymentResult = params['payment'];
    });
    /*this.routeAR.params.subscribe(params => {
      this.paymentResult = params['payment'];
    });*/
    this.invoicingAddress = new Address();
    this.forwardingAddress = new Address();
    this.calculateTotal();
  }

  calculateTotal() {
    this.globals.shoppingCart.forEach(product => {
      // this.subTotal += product.PRICE * product.QUANTITY;
      // this.totalPrice += (product.PRICE * product.QUANTITY) * ((product.VAT / 100) + 1);
      this.subTotal += product.PRICE;
      this.totalPrice += product.PRICE;
    });
  }

  deleteProduct(index: number) {
    this.subTotal = 0.00;
    this.totalPrice = 0.00;
    this.globals.shoppingCart.splice(index, 1);
    this.calculateTotal();
  }

  payment() {
    const code = '351402102';
    const terminal = '1';
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now.valueOf() - start.valueOf();
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    const order = day + now.getFullYear().toString().substring(2,4) + now.getHours().toString() + now.getMinutes().toString() + now.getSeconds().toString();
    const amount = this.totalPrice * 100;
    const currency = '978';   // Euro €
    const transactionType = '0';
    const urlMerchant = 'https://www.capatest.com';
    // const urlwebOk = 'https://www.capatest.com';
    const urlwebOk = '';
    const urlwebKo = '';

    this.paymentObj = new Payment();
    this.paymentReqObj = new PaymentReq();

    this.paymentObj.DS_MERCHANT_AMOUNT = amount.toString();
    this.paymentObj.DS_MERCHANT_ORDER = order;
    this.paymentObj.DS_MERCHANT_MERCHANTCODE = code;
    this.paymentObj.DS_MERCHANT_CURRENCY = currency;
    this.paymentObj.DS_MERCHANT_TRANSACTIONTYPE = transactionType;
    this.paymentObj.DS_MERCHANT_TERMINAL = terminal;
    this.paymentObj.DS_MERCHANT_MERCHANTURL = urlMerchant;
    this.paymentObj.DS_MERCHANT_URLOK = urlwebOk;
    this.paymentObj.DS_MERCHANT_URLKO = urlwebKo;

    if (this.globals.recaptcha) {
      this.serverService.getGoodParameter(this.paymentObj).subscribe(response => {
        setTimeout(() => {
          this.paymentReqObj = plainToClass(PaymentReq, response.body);
        }, 2000);
        this.translate.get('CONFIRMING_SHOPPING').subscribe(value => { this.message = value; });
      }, (error) => {
        console.log('PAYMENT KO :(');
        console.log(error);
      });
    }
  }

}
