import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { growUpDown, rotateArrow, Globals } from '../../globals';
import * as $ from 'jquery';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [growUpDown, rotateArrow]
})
export class MenuComponent implements OnInit, OnDestroy {

  public localStorage = localStorage;
  public menuOpen = false;
  public showLanguages = false;
  public showProducts = false;
  public showBrandsSubmenu = false;
  public lang;
  public window;
  public translateSubscribe = [];

  /* FontAwesome Icons */
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;

  constructor(public translate: TranslateService, public globals: Globals) {
    this.lang = this.localStorage.getItem('lang');
    if (this.lang === null) {
      this.lang = 'es_ES';
    }
    switch (this.lang) {
      case 'es_ES':
      case 'es-ES':
      case 'es':
        this.translate.use('es_ES');
        this.lang = 'es_ES';
        this.localStorage.setItem('lang', 'es_ES');
        break;
      case 'ca_ES':
      case 'ca-ES':
      case 'ca':
      case 'cat':
        this.translate.use('ca_ES');
        this.lang = 'ca_ES';
        this.localStorage.setItem('lang', 'ca_ES');
        break;
      case 'en_US':
      case 'en-US':
      case 'en':
        this.translate.use('en_US');
        this.lang = 'en_US';
        this.localStorage.setItem('lang', 'en_US');
        break;
      case 'de_DE':
      case 'de-DE':
      case 'de':
        this.translate.use('de_DE');
        this.lang = 'de_DE';
        this.localStorage.setItem('lang', 'de_DE');
        break;
      default:
        this.translate.use('en_US');
        this.lang = 'en_US';
        this.localStorage.setItem('lang', 'en_US');
        break;
    }
    this.window = window;
    this.translateSubscribe.push(this.translate.get('CERTIFICATE_URL').subscribe(value => { this.globals.certificateURL = value; }));
    this.translateSubscribe.push(this.translate.get('URL_CHROME').subscribe(value => { this.globals.urlChrome = value; }));
    this.translateSubscribe.push(this.translate.get('URL_EXPLORER').subscribe(value => { this.globals.urlExplorer = value; }));
    this.translateSubscribe.push(this.translate.get('URL_EDGE').subscribe(value => { this.globals.urlEdge = value; }));
    this.translateSubscribe.push(this.translate.get('URL_SAFARI').subscribe(value => { this.globals.urlSafari = value; }));
    this.translateSubscribe.push(this.translate.get('URL_FIREFOX').subscribe(value => { this.globals.urlFirefox = value; }));
    this.translateSubscribe.push(this.translate.get('URL_OPERA').subscribe(value => { this.globals.urlOpera = value; }));
  }

  ngOnInit() {
    this.globals.selectDefaultMenu();
  }

  actionMenu() {
    const onePageNav = $('.onepage-nav > ul');
    (onePageNav.hasClass('active')) ? onePageNav.removeClass('active') : onePageNav.addClass('active');
    this.menuOpen = !this.menuOpen;
  }

  actionLanguages() {
    this.showLanguages = !this.showLanguages;
  }

  changeLanguage(language) {
    this.lang = language;
    this.translate.use(language);
    localStorage.setItem('lang', language);
    this.translateSubscribe.push(this.translate.get('CERTIFICATE_URL').subscribe(value => { this.globals.certificateURL = value; }));
    this.translateSubscribe.push(this.translate.get('URL_CHROME').subscribe(value => { this.globals.urlChrome = value; }));
    this.translateSubscribe.push(this.translate.get('URL_EXPLORER').subscribe(value => { this.globals.urlExplorer = value; }));
    this.translateSubscribe.push(this.translate.get('URL_EDGE').subscribe(value => { this.globals.urlEdge = value; }));
    this.translateSubscribe.push(this.translate.get('URL_SAFARI').subscribe(value => { this.globals.urlSafari = value; }));
    this.translateSubscribe.push(this.translate.get('URL_FIREFOX').subscribe(value => { this.globals.urlFirefox = value; }));
    this.translateSubscribe.push(this.translate.get('URL_OPERA').subscribe(value => { this.globals.urlOpera = value; }));

    location.reload();
  }

  ngOnDestroy(): void {
    for (const trans of this.translateSubscribe) {
      trans.unsubscribe();
    }
  }

}
