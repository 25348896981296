import {JsonObject, JsonProperty} from 'json2typescript';

//@JsonObject('Product')
export class Payment {

    @JsonProperty('DS_MERCHANT_AMOUNT', String)
    DS_MERCHANT_AMOUNT: string;
    @JsonProperty('DS_MERCHANT_CURRENCY', String)
    DS_MERCHANT_CURRENCY: string;
    @JsonProperty('DS_MERCHANT_ORDER', String)
    DS_MERCHANT_ORDER: string;
    @JsonProperty('DS_MERCHANT_MERCHANTCODE', String)
    DS_MERCHANT_MERCHANTCODE: string;
    @JsonProperty('DS_MERCHANT_TERMINAL', String)
    DS_MERCHANT_TERMINAL: string;
    @JsonProperty('DS_MERCHANT_TRANSACTIONTYPE', String)
    DS_MERCHANT_TRANSACTIONTYPE: string;
    @JsonProperty('DS_MERCHANT_MERCHANTURL', String)
    DS_MERCHANT_MERCHANTURL: string;
    @JsonProperty('DS_MERCHANT_URLOK', String)
    DS_MERCHANT_URLOK: string;
    @JsonProperty('DS_MERCHANT_URLKO', String)
    DS_MERCHANT_URLKO: string;
    // @JsonProperty('DS_MERCHANT_MERCHANTNAME', String)
    // DS_MERCHANT_MERCHANTNAME: string;
    // @JsonProperty('DS_MERCHANT_CONSUMERLANGUAGE', String)
    // DS_MERCHANT_CONSUMERLANGUAGE: string;

    constructor() {
      this.DS_MERCHANT_AMOUNT = undefined;
      this.DS_MERCHANT_CURRENCY = undefined;
      this.DS_MERCHANT_ORDER = undefined;
      this.DS_MERCHANT_MERCHANTCODE = undefined;
      this.DS_MERCHANT_TERMINAL = undefined;
      this.DS_MERCHANT_TRANSACTIONTYPE = undefined;
      this.DS_MERCHANT_MERCHANTURL = undefined;
      this.DS_MERCHANT_URLOK = undefined;
      this.DS_MERCHANT_URLKO = undefined;
      // this.DS_MERCHANT_MERCHANTNAME = undefined;
      // this.DS_MERCHANT_CONSUMERLANGUAGE = undefined;
    }
  }
