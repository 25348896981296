import { Component, OnInit } from '@angular/core';
import { fadeInOut, Globals } from 'src/app/globals';
declare var $ : any;

@Component({
  selector: 'app-moviltest',
  templateUrl: './moviltest.component.html',
  styleUrls: ['./moviltest.component.css'],
  animations: [fadeInOut]
})

export class MoviltestComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
    $('#moviltestCarousel').carousel('cycle');
    $('#moviltestCarousel').hover(() => {
      $('#moviltestCarousel').carousel('cycle');
    });
  }

}
