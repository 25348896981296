import { Component, OnInit } from '@angular/core';
import { fadeInOut, Globals } from 'src/app/globals';
declare var $ : any;

@Component({
  selector: 'app-avlditest',
  templateUrl: './avlditest.component.html',
  styleUrls: ['./avlditest.component.css'],
  animations: [fadeInOut]
})
export class AvlditestComponent implements OnInit {

  quantityMDS650: number = 1;
  quantityMDS450: number = 1;
  quantityMDS418: number = 1;
  quantityMDS305NKW: number = 1;
  quantityMDS350: number = 1;
  quantityMDS250: number = 1;
  quantityCDS450: number = 1;
  quantityCDS350: number = 1;
  quantityCDS250: number = 1;
  quantitySPEED2000: number = 1;

  constructor(public globals: Globals) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
    $('#avlCarousel').carousel('cycle');
    $('#avlCarousel').hover(() => {
      $('#avlCarousel').carousel('cycle');
    });
  }

}
