import {JsonObject, JsonProperty} from 'json2typescript';

export class PaymentReq {

  @JsonProperty('Ds_SignatureVersion', String)
  Ds_SignatureVersion: string;
  @JsonProperty('Ds_MerchantParameters', String)
  Ds_MerchantParameters: string;
  @JsonProperty('Ds_Signature', String)
  Ds_Signature: string;

  constructor() {

  }
}
