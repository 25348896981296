import {JsonObject, JsonProperty} from 'json2typescript';

export class Product {

  @JsonProperty('ID', Number)
  ID: number;
  @JsonProperty('NAME', String)
  NAME: string;
  @JsonProperty('DESCRIPTION', String)
  DESCRIPTION: string;
  @JsonProperty('PRICE', Number)
  PRICE: number;

  constructor() {
    this.ID = undefined;
    this.NAME = undefined;
    this.DESCRIPTION = undefined;
    this.PRICE = undefined;
  }
}
