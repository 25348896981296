import { Component, OnInit } from '@angular/core';
import { fadeInOut, Globals } from 'src/app/globals';
import { Routes, RouterModule, Router } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-carbonzapp',
  templateUrl: './carbonzapp.component.html',
  styleUrls: ['./carbonzapp.component.css'],
  animations: [fadeInOut]
})
export class CarbonzappComponent implements OnInit {

  public clientsList = [];
  
  constructor(public globals: Globals, private router: Router) { }

  ngOnInit() {
    var link = "https://www.carbonzapp-iberica.com/";
    window.open(link, "_blank");
    
    this.router.navigate(['']);
    
  }

  addClients() {
    this.clientsList.push( { NAME: 'ABRERA RECAMBIOS DIESEL', LOGO: ''} );
    this.clientsList.push( { NAME: 'ANDIKO MOTOR', LOGO: ''} );
    this.clientsList.push( { NAME: 'ASYSUM', LOGO: ''} );
    this.clientsList.push( { NAME: 'AUTO DIESEL OLOT', LOGO: ''} );
    this.clientsList.push( { NAME: 'AUTO DIESEL VIC', LOGO: ''} );
    this.clientsList.push( { NAME: 'AUTO REPARACIONES SANTA ANA', LOGO: ''} );
    this.clientsList.push( { NAME: 'AUTODIESEL MARINA ALTA', LOGO: ''} );
    this.clientsList.push( { NAME: 'AUTOMOCIÓN MANGUDO', LOGO: ''} );
    this.clientsList.push( { NAME: 'AVESA', LOGO: ''} );
    this.clientsList.push( { NAME: 'CASTY ROMER', LOGO: ''} );
    this.clientsList.push( { NAME: 'DCR DAVID CARRACERO', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL ALMORADI', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL BARBERÁ', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL EMILIO', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL GAVILAN', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL JORDAN', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL MAINAR', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL TURBO GRANADA', LOGO: ''} );
    this.clientsList.push( { NAME: 'DIESEL UTRERA', LOGO: ''} );
    this.clientsList.push( { NAME: 'ELECTRIAUTO', LOGO: ''} );
    this.clientsList.push( { NAME: 'HIJOS DE JUAN SALIDO', LOGO: ''} );
    this.clientsList.push( { NAME: 'IMPORAUTO', LOGO: ''} );
    this.clientsList.push( { NAME: 'INJECCIO DIESEL JORDÀ', LOGO: ''} );
    this.clientsList.push( { NAME: 'IREAUTO', LOGO: ''} );
    this.clientsList.push( { NAME: 'JAICER ELECTROMECÁNICA', LOGO: ''} );
    this.clientsList.push( { NAME: 'LA CASA DEL DIESEL', LOGO: ''} );
    this.clientsList.push( { NAME: 'LAB TECNICO DEL AUTOMOVIL', LOGO: ''} );
    this.clientsList.push( { NAME: 'LIZARTE', LOGO: ''} );
    this.clientsList.push( { NAME: 'MANUEL GAMBIN', LOGO: ''} );
    this.clientsList.push( { NAME: 'MEDINABÍ', LOGO: ''} );
    this.clientsList.push( { NAME: 'MODIESEL', LOGO: ''} );
    this.clientsList.push( { NAME: 'MONTECINO MOTOR', LOGO: ''} );
    this.clientsList.push( { NAME: 'PUERTO DIESEL', LOGO: ''} );
    this.clientsList.push( { NAME: 'RECAMBIOS MOSTOLES', LOGO: ''} );
    this.clientsList.push( { NAME: 'RECTIFICADOS LAMPLE', LOGO: ''} );
    this.clientsList.push( { NAME: 'REPARACIONES HERLICZKA', LOGO: ''} );
    this.clientsList.push( { NAME: 'SAYGAL', LOGO: ''} );
    this.clientsList.push( { NAME: 'SILCOMPEC', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLER INYECCIÓN ALDA', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLER INYECCIÓN DOMINGUEZ', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLER MECANICO GANZA', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLERES DAOIZ', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLERES DIESEL LEON', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLERES FACAL', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLERES PABLO MORENO', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLERES RUFRE', LOGO: ''} );
    this.clientsList.push( { NAME: 'TALLERES VILLA LAÍN', LOGO: ''} );
    this.clientsList.push( { NAME: 'TECNODIESEL MURCIA', LOGO: ''} );
    this.clientsList.push( { NAME: 'TECNODIESEL VALENCIA', LOGO: ''} );
    this.clientsList.push( { NAME: 'TRIDIESEL', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO DIESEL ALMERIA', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO DIESEL BCN 2007', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO DIESEL CAMPOS', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO DIESEL GUERRERO', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO DIESEL INJECCIÓ', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO DIESEL LAGUNAK', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO DIESEL SAYMAR', LOGO: ''} );
    this.clientsList.push( { NAME: 'TURBO INYECCIÓN GIRONA 2017', LOGO: ''} );
    this.clientsList.push( { NAME: 'VEIMPA MOTOR', LOGO: ''} );
    this.clientsList.push( { NAME: 'ZAMACOLA DIESEL', LOGO: ''} );
  }

}
