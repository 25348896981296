import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MoviltestComponent } from './components/moviltest/moviltest.component';
import { AvlditestComponent } from './components/avlditest/avlditest.component';
import { CarbonzappComponent } from './components/carbonzapp/carbonzapp.component';
import { SonicComponent } from './components/sonic/sonic.component';
import { CarboncleanComponent } from './components/carbonclean/carbonclean.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { ContactComponent } from './components/contact/contact.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { LoginComponent } from './components/login/login.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'moviltest', component: MoviltestComponent },
  { path: 'avlditest', component: AvlditestComponent },
  { path: 'carbonzapp', component: CarbonzappComponent },
  { path: 'sonic', component: SonicComponent },
  { path: 'carbonclean', component: CarboncleanComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about_us', component: AboutUsComponent },
  { path: 'cookies_policy', component: CookiesPolicyComponent },
  { path: 'privacy_policy', component: PrivacyPolicyComponent },
  { path: 'login', component: LoginComponent },
  { path: 'shopping_cart', component: ShoppingCartComponent },
  { path: 'shopping_cart/payment/ok', component: ShoppingCartComponent },
  { path: 'shopping_cart/payment/ko', component: ShoppingCartComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

export const APP_ROUTING = RouterModule.forRoot(routes, { useHash: false });

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

  constructor(private router: Router) {
    this.router.navigate(['']);
  }
}
