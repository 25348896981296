import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public email: string;
  public password: string;
  private url = "https://login.capatest.com/web";
  private EP_Login = '/login';

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  login(form) {
    let body = 'login=' + this.email + '&password=' + this.password + '&redirect=/web?';
    const headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    const response = this.http.post(this.url.concat(this.EP_Login), body, {headers: headers, observe: 'response', responseType: 'text'}).subscribe(response => {
      console.log(response);
    }, (error) => {
      console.log(error);
    });
  }

}
