import { Component, OnInit } from '@angular/core';
import { fadeInOut, Globals } from 'src/app/globals';
declare var $ : any;

@Component({
  selector: 'app-carbonclean',
  templateUrl: './carbonclean.component.html',
  styleUrls: ['./carbonclean.component.css'],
  animations: [fadeInOut]
})
export class CarboncleanComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
    $('#carbonCleanCarousel').carousel('cycle');
    $('#carbonCleanCarousel').hover(() => {
      $('#carbonCleanCarousel').carousel('cycle');
    });
  }

}
