import { Component, OnInit, OnDestroy } from '@angular/core';
import { fadeInOut, Globals } from 'src/app/globals';
import { TranslateService } from '@ngx-translate/core';

declare var $ : any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  animations: [fadeInOut]
})
export class AboutUsComponent implements OnInit {

  public aboutUsShow = false;

  currentLanguage: string;

  constructor(public globals: Globals, private translate: TranslateService) {
      this.currentLanguage = this.translate.currentLang;
   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.globals.selectDefaultMenu();
    this.aboutUsShow = true;
    $('#aboutUsCarousel').carousel('cycle');
    $('#aboutUsCarousel').hover(() => {
      $('#aboutUsCarousel').carousel('cycle');
    });

    console.log(this.currentLanguage);
  }

}
