import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payment } from 'src/app/model/payment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

    constructor(private http: HttpClient) { }

    /**
     * Set general headers
     */
    private static setHeaders(): HttpHeaders {
      let headers = new HttpHeaders();
      headers = headers.append('content-type', 'application/json');
      return headers;
    }

    getGoodParameter(payment: Payment): Observable<any> {
      const body = JSON.stringify(payment);
      const url = 'https://backend.capatest.com:8520/api/getRequest';
      return this.http.post(url, body, {headers: ServerService.setHeaders(), observe: 'response', responseType: 'json'});
  }

    doPayment(version: string, merchantParameters: string, clave: string): Observable<any> {
        const body = JSON.stringify({ Ds_SignatureVersion: version, Ds_MerchantParameters: merchantParameters, Ds_Signature: clave });
        const url = 'https://sis-t.redsys.es:25443/sis/realizarPago';
        return this.http.post(url, body, {headers: ServerService.setHeaders(), observe: 'response', responseType: 'text'});
    }

    getProductByID(id: number): Observable<any> {
      const url = 'https://backend.capatest.com:8520/api/getProduct/' + id;
      return this.http.get(url, {headers: ServerService.setHeaders(), observe: 'response'});
    }

    verifyReCaptcha(secret: string, token: string): Observable<any> {
      const url = 'https://www.google.com/recaptcha/api/siteverify?secret=' + secret + '&response=' + token;
      return  this.http.get(url, {headers: ServerService.setHeaders(), observe: 'response'});
    }


}
